.navbar {
  color: var(--pinkContrast);
}

.NavLogo {
  width: 70px;
  height: 70px;
}
.TextMain {
  font-family: var(--tipografia);
  font-style: normal;
  font-weight: 400;
  font-size: 30px;
  line-height: 59px;
}
