* {
  box-sizing: border-box;
}

/*Estilos para el input y button*/
.searchBox {
  width: fit-content;
  height: fit-content;
  position: relative;
  display: flex;
  z-index: 3;
  box-shadow: none;
  background: rgba(245, 56, 158, 0.15);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 4px 4px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(40px);
  border-radius: 42px;
  
}
.inputSearch {
  height: 50px;
  width: 50px;
  border-style: none;
  padding: 10px;
  font-size: 18px;
  letter-spacing: 2px;
  outline: none;
  border-radius: 25px;
  transition: all 0.5s ease-in-out;
  background-color: rgba(245, 56, 158, 0.15);
  padding-right: 40px;
  color: #fff;
  font-family: var(--tipografia);
}
.inputSearch::placeholder {
  color: rgb(245, 245, 245);
  font-size: 18px;
  letter-spacing: 2px;
  font-weight: 100;
}
.btnSearch {
  width: 50px;
  height: 50px;
  border-style: none;
  font-size: 20px;
  font-weight: bold;
  outline: none;
  cursor: pointer;
  border-radius: 50%;
  position: absolute;
  right: 0px;
  color: #ffffff;
  background-color: transparent;
  pointer-events: painted;
}
.btnSearch:focus ~ .inputSearch {
  width: 800px;
  border-radius: 0px;
  background-color: transparent;
  transition: all 500ms cubic-bezier(0, 0.11, 0.35, 2);
}
.inputSearch:focus {
  width: 800px;
  border-radius: 0px;
  background-color: transparent;
  transition: all 500ms cubic-bezier(0, 0.11, 0.35, 2);
}

@media (max-width: 750px) {
  .btnSearch:focus ~ .inputSearch {
    width: 600px;
  }
  .inputSearch:focus{
    width: 600px;
  }
}

@media (max-width: 600px) {
  .btnSearch:focus ~ .inputSearch {
    width: 400px;
  }
  .inputSearch:focus{
    width: 400px;
  }
}