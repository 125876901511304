.container{
position: relative;
}
.container *{margin: 0;}

.absolute{

    width: 100%;
    text-align: center;
position: absolute;
top: 55%;
left: 50%;
transform: translate(-50%, -50%);
z-index: -5;
}

.absolute > h2{color: var(--pinkContrast)}