.lienzo {
  height: 100vh;
  color: aliceblue;
  font-family: var(--tipografia);
}

.divPadre {
  padding: 20px;
  background: var(--bg);
}

.nameApp{
  color: var(--pinkContrast);
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
}

.LogoProp{
  width: 100%;
  height: 250px;
  margin-bottom: 22px;
}

.content {
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.leyenda{
  font-size: 25px;
  font-weight: 400;
  font-style: normal;
}

.pie {
  display: flex;
  justify-content: space-between;
}