:root {
  --red: red;
  --bg:linear-gradient(180deg, #2A0944 0%, #A12568 100%);
  --pinkContrast: #FF4A76;
  --tipografia: "Space Mono";
  --bgScroll: rgba(17, 14, 14, 0.527);
  --bgScrollHover: #b3b3b3;

}


body {
  margin: 0;
  font-family: 'Space Mono', monospace;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


