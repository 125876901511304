.container{
    display: flex;
    align-items: center;
    padding: 4px 10px;
    /* From https://css.glass */
background: rgba(255, 255, 255, 0.03);
border-radius: 8px;
box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
backdrop-filter: blur(5px);
-webkit-backdrop-filter: blur(5px);
/* border: 1px solid rgba(255, 255, 255, 0.3); */

}
.container > * + *{margin-left: 1rem;}

.circle{
    width: 50px;
    height: 50px;
    border-radius: 100%;
    background: hsl(256, 100%, 65%);
    background-repeat:no-repeat;
    background-position:center;
    background-size: cover;
}


.TLOSIcon{
    position: absolute;
    width: 50%;
    height: auto;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.ticker{
    margin-left: auto;
}