.item{
  width: 100%;
  /*height: 250px;*/
  position: relative;
}

.overlay{
  position: absolute;
  display: flex;
  top: 0;
  width: 100%;

}

.overlay > h3{
  margin: 0;
  font-size: 40px;
  color: var(--pinkContrast);
}

.TLOSIcon{
  width: 40px ;
  height: auto;
  margin-right: .5rem;
  
}
.TLOSIcon *{fill:var(--pinkContrast) ;}

.overlay > h4{
  color: white;
  margin-left: auto !important;
}